const categoryColors = {
    open: "#bae2a6",
    closed: "#e2a6a7",
    common: "#f9ea86"
  };

const usersFullName = {
  hans: 'Hans & Helen',
  margareta: 'Margareta & Mats',
  fredrik: 'Fredrik & Christina',
  sofia: 'Sofia & Jacob',
  niklas: 'Niklas & Jonna',
  caroline: 'Caroline & Daniel',
  gemensam: 'Gemensam planering',
  test: 'Testkonto'
};

const mockEvents = [
    {
      id: '1',
      title: 'caroline',
      start: '2024-02-09',
      end: '2024-02-09',
      category: 'open',
      description: 'Där med familjen',
    },
    {
      id: '2',
      title: 'hans',
      start: '2024-02-12',
      end: '2024-02-15',
      category: 'closed',
      description: 'Helenes barn på besök',
    },
  ];
  export {mockEvents, categoryColors, usersFullName};
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const LoginForm = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const history = useNavigate(); // Use the useHistory hook for navigation

  useEffect(() => {
    // Check if the session token exists and is valid (assuming a simplistic check here)
    const token = localStorage.getItem('sessionUserNameAsa');
    if (token) {
      // Optionally, you could add an API call here to validate the token's authenticity and expiration
      history('/main'); // Redirect to the main app route if a token is found
    }
  }, [history]);

  const handleSubmit = async (e) => {
    let data = "";
    if(e.target.elements[0].value !== 'test')
    {
      e.preventDefault();
      const response = await fetch('https://husetiasa.se/api/login.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });
      data = await response.json();
    }
    else
    {
      data = "success"
    }

    if (data === "success") {
        localStorage.setItem('sessionUserNameAsa', username.toLocaleLowerCase());
        history('/main'); 
    } else {
      setError(data.message || 'Login failed');
    }
  };

  return (
    <div className="loginform-content">
    <form onSubmit={handleSubmit}>
      <div>
        <label>Användarnamn:</label>
        <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
      </div>
      <div>
        <label>Lösenord:</label>
        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
      </div>
      {error && <p>{error}</p>}
      <button type="submit">Logga in</button>
    </form>
    </div>
  );
};

export default LoginForm;
import React, { useState, useEffect } from 'react';
import {usersFullName} from '../services/mockEvents'; 

const Modal = ({ isOpen, type, eventData, onAction, onClose, onEdit }) => {
  const [formState, setFormState] = useState(eventData||{});
  useEffect(() => {
    setFormState(eventData||{});
  }, [eventData]);

  if (!isOpen) return null;

  function downloadIcsFile(event) {
    const { start, end, description, title } = event;
    const startDate = new Date(start);
    const endDate = new Date(end);
  
    // Format dates to YYYYMMDDTHHMMSSZ for the ICS file
    const startFormatted = startDate.toISOString().replace(/-|:|\.\d+/g, '');
    const endFormatted = endDate.toISOString().replace(/-|:|\.\d+/g, '');
  
    const icsContent = [
      'BEGIN:VCALENDAR',
      'VERSION:2.0',
      'BEGIN:VEVENT',
      `DTSTART:${startFormatted}`,
      `DTEND:${endFormatted}`,
      `SUMMARY:${'Huset i Åsa: '+ usersFullName[title]}`,
      `DESCRIPTION:${description || ''}`,
      'END:VEVENT',
      'END:VCALENDAR'
    ].join('\n');
  
    const blob = new Blob([icsContent], { type: 'text/calendar;charset=utf-8' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'event.ics'); // Specify the download file name
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onAction(type, formState);
  };

  if (type === 'view') {
    // Render view-only layout
    return (
      <div className="modal-backdrop">
        <div className="modal-content">
            <h2>Visa händelse</h2>
            <div><strong>Familj:</strong> {formState.category=='common' ? 'Gemensam aktivitet' : usersFullName[formState.title]}</div>
            <div><strong>Beskrivning:</strong> {formState.description || ' '}</div>
            <div><strong>Start:</strong> {new Date(formState.start).toLocaleDateString()}</div>
            <div><strong>Slut:</strong> {new Date(formState.end).toLocaleDateString()}</div>
            <div className="form-actions">
            <button onClick={() => onEdit(formState)} type="button">Redigera</button>
            <button 
              onClick={() => {
                const isConfirmed = window.confirm('Är du säker på att du vill ta bort den här händelsen?');
                if (isConfirmed) {
                  onAction('delete', formState.id);
                }
              }} 
              type="button">
              Ta bort
            </button>
            <button onClick={onClose} type="button">Stäng</button>
            <button onClick={() => downloadIcsFile(formState)} type="button" title='Ladda ner kalenderhändelse för import till valfri kalender' aria-label="Ladda ner som ICS" style={{border: 'none'}}>
                <i className="fas fa-download"></i> {/* Icon for the button */}
            </button>
            </div>
        </div>
      </div>
    );
  }

  // Assuming 'create' and 'update' share a similar form structure
  else if(type === 'create' || type === 'update')
  {
    return (
      <div className="modal-backdrop">
        <div className="modal-content">
          <form onSubmit={handleSubmit}>
          <label>Familj:</label>
            <select
                  id="title"
                  name="title"
                  value={formState.title}
                  onChange={handleChange}
              >
                  <option value="margareta">Margareta & Mats</option>
                  <option value="hans">Hans & Helene</option>
                  <option value="sofia">Sofia & Jacob</option>
                  <option value="caroline">Caroline & Daniel</option>
                  <option value="niklas">Niklas & Jonna</option>
                  <option value="fredrik">Fredrik & Christina</option>
              </select>
          <label htmlFor="category">Typ av händelse:</label>
              <select
                  id="category"
                  name="category"
                  value={formState.category}
                  onChange={handleChange}
                  required
              >
                  <option value="open">Vi är här! Kom gärna och umgås</option>
                  <option value="closed">Vi är här! Föredrar att vara ensamma</option>
                  <option value="common">Gemensam händelse</option>
              </select>
            
            <label>Beskrivning:</label>
              <textarea
                name="description"
                value={formState.description || ''}
                onChange={handleChange}
              /> 
            <label>Start:</label>
              <input
                type="date"
                name="start"
                value={new Date(formState.start).toLocaleDateString()}
                onChange={handleChange}
                required
              />
            
            <label>Slut:</label>
              <input
                type="date"
                name="end"
                value={new Date(formState.end).toLocaleDateString()}
                onChange={handleChange}
                required
              />
            
            <div className="form-actions">
              <button type="submit">{type === 'create' ? 'Skapa' : 'Uppdatera'}</button>
              <button type="button" onClick={onClose}>Avbryt</button>
            </div>
          </form>
        </div>
      </div>
    );
  }
};

export default Modal;
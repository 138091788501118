import {mockEvents} from '../services/mockEvents'; 

const API_BASE_URL = 'https://husetiasa.se/api'; // Adjust to your actual API URL

let events = [...mockEvents]; // Clone mockEvents to simulate a "database"

export const fetchEventsMock = () => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(events), 500); // Simulate network delay
  });
};

export const fetchEvents = async () => {
  const response = await fetch(`${API_BASE_URL}/view_events.php`);
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return await response.json();
};

export const createEvent = async (eventData) => {
  const response = await fetch(`${API_BASE_URL}/create_event.php`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(eventData),
  });
  return await response.json();
};

export const updateEvent = async (eventData) => {
  await fetch(`${API_BASE_URL}/update_event.php`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(eventData),
  });
};

export const deleteEvent = async (id) => {
  await fetch(`${API_BASE_URL}/delete_event.php`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ id }),
  });
};

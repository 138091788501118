import React from 'react';
import './App.css';
import CalendarComponent from './components/CalendarComponent';
import LoginForm from './components/LoginForm';
import { Route, Routes } from 'react-router-dom';

const App = () => {

  return (
    <Routes>
        <Route path="/login" element={<LoginForm/>} />
        <Route path="/main" element={<CalendarComponent/>} />
        {/* Redirect to login by default */}
        <Route path="/" element={<LoginForm/>} />
    </Routes>
  );
};
export default App;
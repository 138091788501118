import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction'; // for selectable
import Modal from './Modal'; // Unified modal component
import { fetchEventsMock,fetchEvents, createEvent, updateEvent, deleteEvent } from '../services/eventService';
import { Link, useNavigate } from 'react-router-dom';
import {categoryColors, usersFullName} from '../services/mockEvents'; 
import svLocale from '@fullcalendar/core/locales/sv'

const CalendarComponent = () => {
  const history = useNavigate();
  const username = localStorage.getItem('sessionUserNameAsa');
  const fullName = username ? usersFullName[username.toLocaleLowerCase()] : 'Unknown User';
  const [events, setEvents] = useState([]);
  const [modalProps, setModalProps] = useState({ isOpen: false, type: null, eventData: null });

  useEffect(() => {
    if(!username)
    {
      history('/login');
    }

   fetchEvents().then(fetchedEvents => {
    fetchedEvents = fetchedEvents.map(event => ({
          ...event, 
          allDay : formatDate(event.start) == formatDate(event.end) ? true : false,
          rendering: 'background', // Add your custom property here
          backgroundColor: categoryColors[event.category],
          end:  `${formatDate(event.end)}T08:00:00`
        }));
        setEvents(fetchedEvents);
      });
  }, []);

  const handleEventAction = (action, eventData) => {
    switch(action) {
      case 'create':
          createEvent(eventData).then((newEvent) => {     
            eventData.id = Number(newEvent.id);
            if(eventData.start == eventData.end)
            {
              eventData.allDay = true;
            }
            eventData.start= `${formatDate(eventData.start)}`;
            eventData.end = `${formatDate(eventData.end)}T08:00:00`;
            eventData.backgroundColor = categoryColors[eventData.category]; 
            setEvents([...events, eventData]);
            closeModal();
          });
          
        break;
      case 'update':
        eventData.allDay = formatDate(eventData.start) == formatDate(eventData.end) ? true : false;
        eventData.start= `${formatDate(eventData.start)}`;
        eventData.end = `${formatDate(eventData.end)}T08:00:00`;
        eventData.backgroundColor = categoryColors[eventData.category];
        setEvents(events => events.map(event => event.id === eventData.id ? eventData : event));
        updateEvent(eventData).then(() => {
          closeModal();
        });
 
        break;
      case 'delete':
        console.log(eventData);
        setEvents(events.filter(event => event.id !== eventData));
        deleteEvent(eventData).then(() => {
          closeModal();
        });
        break;
      default:
        console.error('Unsupported action');
       
    }
    closeModal();
  };

  const openModal = (type, eventData = null) => setModalProps({ isOpen: true, type, eventData });
  const closeModal = () => setModalProps({ isOpen: false, type: null, eventData: null });

  const handleLogout = (event) => {
    event.preventDefault();
    localStorage.removeItem('sessionUserNameAsa'); // Clear the username from localStorage
    history('/login'); // Redirect to the login form
  };

  const handleNewEventClick = () => {
    const event = ({
      title: username,
      category:'open',
      desscription: '',
      start: '',
      end: '',
      backgroundColor: '',
    });
    openModal('create', event);
  };

  const handleDateClick = (arg) => {
    // Format clicked date and time for datetime-local input
    const startStr = formatDate(arg.date);
    const event = ({
      title: username,
      category:'open',
      desscription: '',
      start: startStr,
      end: startStr,
      backgroundColor: '',
    });
    openModal('create', event);
  };

  const handleEventClick = (clickInfo) => {
    const event = {
      id: Number(clickInfo.event.id),
      category: clickInfo.event.extendedProps.category,
      title: clickInfo.event.title,
      description: clickInfo.event.extendedProps.description,
      start: clickInfo.event.start,
      end: clickInfo.event.end ? clickInfo.event.end : clickInfo.event.start,
      backgroundColor: clickInfo.event.backgroundColor,
    };
    openModal('view',event)
  };

  const handleEdit = (eventData) => {
    closeModal();
    openModal('update',eventData)
  };

  const renderEventContent = (eventInfo) => {
    return (
      <div style={{ color: '#000', cursor:'pointer', overflow:'hidden' }}>
        {eventInfo.event.extendedProps.category == "common" ? 'Gemensam: ' + eventInfo.event.extendedProps.description : usersFullName[eventInfo.event.title]}
      </div>
    );
  };

  const formatDate = (date) => {
    const local = new Date(date);
    return local.toLocaleDateString()
  };

  return (
    <>
    <div className='main-content-calendar'>
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '0px' }}>
        <h3>{fullName}</h3>
        <Link className='logoutLink' onClick={handleLogout}>Logga ut</Link>
      </div>
    <FullCalendar
     plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
      initialView="dayGridMonth"
      headerToolbar={{
        left: 'prev,next addEventButton',
        center: 'title',
        right: ''
      }}
      customButtons={{
        addEventButton: {
          text: '+',
          title: 'Lägg till händelse',
          click: handleNewEventClick,
        }
      }}
      timeZone ={'UTC'} 
      locale={svLocale}
      editable={false} // Allow event dragging & resizing
      selectable={true} // Allow date selection
      selectMirror={true}
      dayMaxEvents={true}
      displayEventTime={false}
      weekends={true}
      weekNumbers={true}
      events={events} // Bind events from state
      eventClick={handleEventClick} // Handle event clicks
      dateClick={handleDateClick}
      firstDay={1} // Set Monday as the first day of the week
      eventContent={renderEventContent} 
    />
    <Modal {...modalProps} onAction={handleEventAction} onClose={closeModal} onEdit={handleEdit} />
   </div>
  </>
  );
};

export default CalendarComponent;